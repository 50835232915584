import React from "react";

class DefaultHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  renderDropdownIcon() {
    const { isOpen } = this.state
    return (
      <img onClick={this.toggleOpen} src={`/images/landing/${isOpen ? "menuclose.png" : "menuopen.png"}`} className={`sg-landing-page-mobile-menu-dropdown-icon ${isOpen ? "open" : "closed"}`} alt=""/>
    )
  }

  renderDesktopHeader() {
    const { overnight } = this.props;
    return (
      <nav class="navbar navbar-inverse header-desktop" role="navigation">
      </nav>
    )
  }

  renderTabletHeader() {
    const { overnight } = this.props;
    return (
      <nav class="navbar navbar-inverse header-tablet" role="navigation">
        {/* <div className={`landing-page-wrapper`}>
          <div className="container sg-f1-header-container" style={{marginBottom: '-1px'}}>
            <div className="row equal">
              <div className="col-xs-12" style={{textAlign: "center"}}>
                <img src="/images/header/header-icon-tablet.svg" style={{maxWidth: "605px"}}/>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className={`landing-page-wrapper landing-page-subhead`}>
        </div> */}
      </nav>
    )
  }

  renderMobileHeader() {
    const { overnight } = this.props;
    return (
      <nav class="navbar navbar-inverse header-mobile" role="navigation">
        {/* <div className={`landing-page-wrapper`} style={{padding: "45px 0px"}}>
          <div className="container sg-f1-header-container" style={{marginBottom: '-1px'}}>
            <div className="row equal">
              <div className="col-xs-12" style={{textAlign: "center"}}>
                <img src="/images/header/header-icon-mobile.svg" style={{maxWidth: "271px"}}/>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className={`landing-page-wrapper landing-page-subhead`} style={{paddingTop: "10px"}}>
        </div> */}
      </nav>
    )
  }

  renderMenu() {
    const { isOpen } = this.state
    return (
      <div className="landing-page-header-mobile-menu">
        {isOpen ? (
          this.renderMenuInner()
        ):""}
      </div>
    )
  }

  renderMenuInner() {
    const { overnight } = this.props;
    return (
      <div class="landing-page-header-nav-wrapper">
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="home_nav">
            <a href="#home">
            Home
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="schedule_nav">
            <a href="#schedule">
            Schedule
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="parking_nav">
            <a href="#parking">
            Arrival &amp; Check-In
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="ticket_nav">
            <a href="#ticket">
            Your Tickets
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="shuttle_nav">
            <a href="#shuttle">
            Shuttle
            </a>
        </div>
        {
          overnight ?
          <></>
          :
          <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="gate_nav">
            <a href="#gate">
              Gate Times
            </a>
          </div>
        }

        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="security_nav">
            <a href="#security">
            Security
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="faqs_nav">
            <a href="#faqs">
            FAQs
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="contact_nav">
            <a href="#contact">
            Contact Us
            </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderDesktopHeader()}
        {this.renderTabletHeader()}
        {this.renderMobileHeader()}
      </>
    )

  }
}

export default DefaultHeader;
