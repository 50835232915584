import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      console.log(json);
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        console.log('in here');
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className={`registration-page-one container`} style={{padding: "0px"}}>
          <div className="att-proam-registration-email-validation registration-form">
            <div className="row">
              <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left", paddingBottom: "20px", fontSize: "24px", lineHeight: "32px", fontWeight: "400"}}>
                Thank you for confirming your response.
              </div>

              <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left", fontSize: "16px", lineHeight: "22px", fontWeight: "400", marginBottom: "10px"}}>
                If you have any questions, please contact the Cisco Executive Events Team.
              </div>
              <div className="col-xs-12">
                <div className="att-proam-registration-email-validation-submit" style={{textAlign: "left"}}>
                  <a target="_blank" href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202024%20Global%20Citizen%20Festival">
                    <button type="button" style={{paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", width: "auto", marginTop: "20px"}} className="sg-button sg-submit-button">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={`registration-page-one container`} style={{padding: "0px"}}>
        <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form ">
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left", paddingBottom: "20px", fontSize: "24px", lineHeight: "32px", fontWeight: "400"}}>
              Thank you for your response.
            </div>
            <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left", fontSize: "20px", lineHeight: "26px", fontWeight: "400"}}>
              Sorry we will miss you at the 2024 Global Citizen Festival. We look forward to hosting you at a future Cisco event. To confirm your response, please enter your email address below.
              <br/>
            </div>
          </div>
          <div className="col-md-6 att-proam-registration-email-validation-fields" style={{padding: "0px"}}>
            {/* <br/> */}
            <div className="label-wrapper" style={{textAlign: "left"}}>
              <label className="label-english">Enter email address *</label>
            </div>
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value
              })}
              className="form-control"
            />
          </div>
          <div className="col-xs-12"></div>
          {error ? (
            <div className="att-proam-registration-email-validation-error error-red">
              {error}
            </div>
          ):""}
          <div className="col-xs-12 att-proam-registration-email-validation-submit" style={{textAlign: "left", marginTop: "32px", padding: "0px"}}>
            <button
              type="button"
              onClick={this.declineEmail}
              className="sg-button sg-submit-button"
              style={{width: "auto", paddingLeft: "30px", paddingRight: "30px"}}
            >
              Submit
            </button>
          </div>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left", paddingBottom: "20px", fontSize: "16px", lineHeight: "22px", fontWeight: "300", marginTop: "32px"}}>
              If you have any questions, please <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202024%20Global%20Citizen%20Festival">contact</a> the Cisco Executive Events Team.
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RegistrationDecline;
